<template>
  <v-main>
    <v-card
      flat
      tile
    >
      <v-card-title>Файлы</v-card-title>
      <v-breadcrumbs :items="breadcrumbs">
        <template #item="{ item }">
          <v-breadcrumbs-item
            :disabled="item.disabled"
            @click="openFolder(item)"
          >
            <router-link
              v-if="!item.disabled"
              :to="item.to"
            >
              {{
                item.text
              }}
            </router-link>
            <span v-else>{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-data-table
        :headers="headers"
        :items="folder"
        dense
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template #[`item.name`]="{ item }">
          <v-img
            v-if="item.thumbnail_url"
            :src="item.thumbnail_url"
            class="v-icon v-icon--left"
          />
          <v-icon
            v-if="item.size && !item.thumbnail_url"
            left
          >
            mdi-file
          </v-icon>
          <a
            v-if="item.size"
            :href="item.url"
          >{{
            item.name ? item.name : item.original_filename
          }}</a>
          <span
            v-if="!item.size"
            @click="openFolder(item)"
          ><v-icon left>mdi-folder</v-icon><router-link
            :to="{ name: 'FolderView', params: { id: item.id } }"
          >{{ item.name }}</router-link></span>
        </template>
        <template #[`item.size`]="{ item }">
          {{ item.size | file_size }}
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import files from "@/api/files";

export default {
  name: "FolderList",
  data: () => ({
    headers: [
      { text: "Название", value: "name", sortable: false },
      { text: "Размер", value: "size", sortable: false },
    ],
    loading: true,
    data: {
      ancestors: [],
      children: [],
      files: [],
    },
  }),
  computed: {
    folder() {
      if (this.loading) return [];
      return [
        ...this.data.children,
        ...this.data.files.map((el) => ({ ...el, id: "file-" + el.id })),
      ];
    },
    breadcrumbs() {
      if (!this.$route.params.id || !this.data.ancestors)
        return [
          {
            text: "Начало",
            disabled: true,
          },
        ];
      let ancestors = this.data.ancestors.map((el) => ({
        id: el.id,
        text: el.name,
        disabled: false,
        to: { name: "FolderView", params: { id: el.id } },
      }));
      ancestors.unshift({
        text: "Начало",
        disabled: false,
        to: { name: "FolderList" },
      });
      ancestors.push({
        text: this.data.name,
        disabled: true,
      });
      return ancestors;
    },
  },
  created() {
    this.$route.params.id
      ? this.openFolder({ id: this.$route.params.id })
      : this.openFolder();
  },
  methods: {
    openFolder({ id } = {}) {
      this.loading = true;
      files.getFolder(id).then((response) => {
        Array.isArray(response.data)
          ? (this.data = { children: response.data, files: [] })
          : (this.data = response.data);
        this.loading = false;
      });
    },
  },
};
</script>
