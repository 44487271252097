import api from '@/api';

const page_path = '/files/folder/';

export default {
  getFolder(id = "") {
    return new Promise((resolve, reject) => {
      if (id !== "") id = `${id}/`;
      api
        .get(`${page_path}${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    })
  },
}
